import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../../components/Layout'
import BodyColor from '../../components/BodyColor'
import WorkTitleBlock from '../../components/WorkTitleBlock';

export const frontmatter = {
  title: 'D&M Interhoney Logo',
  type: 'Identity',
  client: 'Mir Meda',
  path: '/works/identity/dm-interhoney-logo/',
  date: '2014-06-09',
  image: 'dm-logo-demo',
  work_type: 'Logo',
}

export default ({ location, data }) => (
  <Layout location={location}>
    <Helmet title={frontmatter.title} />
    <BodyColor background="#ffde02" />
    <Image
      fluid={data.file.childImageSharp.fluid}
      alt="DM Interhoney Logo"
      style={{
        maxWidth: 595,
        margin: 'auto',
      }}
    />

    <WorkTitleBlock {...frontmatter} />
  </Layout>
)

export const query = graphql`
  query DMLogoQuery {
    file(relativePath: { eq: "dm-interhoney/dm-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
